import React from "react"
import Project from "../Projects/Project"
import { graphql, useStaticQuery, Link } from "gatsby"
import styles from "../../css/courses.module.css"
import Title from "../Title"

const query = graphql`
  {
    allStrapiProjects(sort: { fields: id, order: DESC }, limit: 6) {
      nodes {
        Description
        ProjectLink
        Title
        id
        projImg {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const Projects = () => {
  const {
    allStrapiProjects: { nodes: projects },
  } = useStaticQuery(query)
  return (
    <section className={styles.courses}>
      <Title title="top" subtitle="projects"></Title>
      <div className={styles.center}>
        {projects.map(item => {
          return <Project key={item.id} {...item}></Project>
        })}
      </div>
      <Link to="/courses" className="btn-primary">
        All Projects
      </Link>
    </section>
  )
}

export default Projects
