import React from "react"
import styles from "../../css/single-course.module.css"
import Image from "gatsby-image"
import logo from "../../images/addict-logo.svg"

const Project = ({ Title, ProjectLink, projImg }) => {
  const mainImage = projImg.childImageSharp.fluid
  return (
    <article className={styles.course}>
      <div className={styles.imgContainer}>
        <Image fluid={mainImage} alt={Title} className={styles.img}></Image>
        <a
          href={ProjectLink}
          className={styles.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          View GitHub
        </a>
      </div>
      <div className={styles.footer}>
        <div className={styles.text}>
          <h3>{Title}</h3>
        </div>
        <img src={logo} alt="WholesomeCorp Logo" />
      </div>
    </article>
  )
}

export default Project
